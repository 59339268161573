<template>
  <FullContent
    :enabled="fullContent"
    :dynamic="heightContent"
    v-slot="{ height, makeElemId }"
    :additionalElementsToWatch="extraFullContentElems"
  >
    <div class="kt-portlet" v-bind:class="portletClass">
      <div
        class="kt-portlet__head"
        :id="fullContent === true || heightContent === true ? makeElemId('head') : undefined"
        v-bind:class="headClass"
        v-if="hasTitleSlot || title"
      >
        <div class="kt-portlet__head-label">
          <slot name="title" v-if="hasTitleSlot"></slot>
          <h3 class="kt-portlet__head-title" v-if="!hasTitleSlot">
            {{ title }}
          </h3>
        </div>
        <div class="kt-portlet__head-toolbar">
          <slot name="toolbar"></slot>
        </div>
      </div>
      <div
        class="kt-portlet__body"
        :style="
          (fullContent === true || heightContent === true ? `height: ${height}px; ` : '') + adBodyStyle
        "
        v-bind:class="{
          bodyClass,
          'kt-portlet__body--fit': bodyFit,
          'kt-portlet__body--fluid': bodyFluid
        }"
      >
        <slot name="body" v-if="fullContent !== true && heightContent !== true"></slot>
        <perfect-scrollbar
          ref="pScroller"
          style="max-height: 90vh; position: relative"
          v-else
        >
          <slot name="body" :height="height" :scrollto="scrollToFunc"></slot>
        </perfect-scrollbar>
      </div>
      <div
        class="kt-portlet__foot kt-portlet__body--fit"
        v-if="hasFootSlot"
        :id="fullContent === true || heightContent === true ? makeElemId('foot') : undefined"
      >
        <slot name="foot"></slot>
      </div>
    </div>
  </FullContent>
</template>

<script>
import FullContent from "@/views/partials/content/FullContent.vue";
export default {
  name: "KTPortlet",
  props: {
    /**
     * String title
     */
    title: String,
    /**
     * Set portlet head size. Eg. md, lg, sm, etc.
     */
    headSize: String,
    /**
     * Set portlet to fluid
     */
    fluidHeight: Boolean,
    /**
     * Set portlet to fluid in half
     */
    fluidHalfHeight: Boolean,
    /**
     * Set overlay head
     */
    headOverlay: Boolean,
    /**
     * Set extra class for portlet head
     */
    headClass: String,
    /**
     * Set extra class for portlet body
     */
    bodyClass: String,
    /**
     * Set portlet body to fit
     */
    bodyFit: Boolean,
    /**
     * Set portlet body to fluid
     */
    bodyFluid: Boolean,
    /**
     * String title
     */
    adBodyStyle: String,

    fullContent: Boolean,
    heightContent: Boolean
  },
  components: {
    FullContent
  },
  methods: {
    scrollToFunc(e) {
      const self = this;
      self.$nextTick(() => {
        if (e === "top") e = 0;
        if (e === "bottom") e = self.$refs.pScroller.$el.scrollHeight;
        self.$refs.pScroller.$el.scrollTop = e;
        setTimeout(() => {
          self.$refs.pScroller.$el.scrollTop = e;
        }, 100);
      });
    }
  },
  computed: {
    extraFullContentElems() {
      let arr = [];
      if (this.hasTitleSlot || this.title) arr.push("head");
      if (this.hasFootSlot) arr.push("foot");
      return arr;
    },
    portletClass() {
      const cls = {
        "kt-portlet--height-fluid": this.fluidHeight,
        "kt-portlet--height-fluid-half": this.fluidHalfHeight,
        "kt-portlet--head-overlay": this.headOverlay
      };
      cls[this.headSizeClass] = this.headSizeClass;
      return cls;
    },
    hasTitleSlot() {
      return !!this.$slots["title"];
    },
    hasFootSlot() {
      return !!this.$slots["foot"];
    },
    headSizeClass() {
      if (this.headSize) {
        return `kt-portlet--head-${this.headSize}`;
      }
      return false;
    }
  }
};
</script>

<style scoped>
.full-content > .kt-portlet {
  padding-bottom: 0;
}
</style>