<template>
  <div :class="dashMode ? 'dashboard-mode' : ''">
    <Brand v-if="dashMode" :hideControls="true" style="background: none" />
    <div class="row" >
      <div class="col-xl-8 col-lg-8 order-lg-3 order-xl-1">
        <KTPortlet
          v-bind:class="'kt-portlet--height-fluid'"
          v-bind="{ bodyFit: true }"
        >
          <template v-slot:body>
            <!-- Insert this tag where you want the widget to render -->
            <iframe
              sandbox="allow-scripts allow-same-origin"
              width="100%"
              height="480"
              src="https://cybermap.kaspersky.com/en/widget/dynamic/dark"
              style="border-radius: 15px"
              frameborder="0"
            ></iframe>
          </template>
        </KTPortlet>
      </div>
      <!-- <div class="col-xl-4 col-lg-4 order-lg-3 order-xl-1">
        <KTPortlet
          v-bind:title="'Groups'"
          v-bind:class="'kt-portlet--tabs kt-portlet--height-fluid'"
        >
          <template v-slot:body>
            
          </template>
        </KTPortlet>
      </div> -->
    </div>
    <div class="row quickfoot">
      <div class="col-10">
        <b-button @click="dashMode = !dashMode" size="sm" variant="text">
          {{ dashMode ? "hide" : "show" }} fullscreen dashboard
        </b-button>
      </div>
      <!-- <div class="col-2" style="text-align: right; padding-right: 20px">
        <b-button @click="updateData(true)" size="sm" variant="text">
          last updated:
          {{ uiLastUpdate }}s ago
        </b-button>
      </div> -->
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import Brand from "@/views/theme/brand/Brand.vue";
import { mapGetters } from "vuex";

export default {
  name: "dashboard",
  components: {
    KTPortlet,
    Brand,
  },
  data() {
    return {
      dashMode: false,
    };
  },
  beforeDestroy() {
    this.$eventBus.off(`switch-service-selected`);
  },
  mounted() {
    //console.log(this.$route);
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Networks" },
      { title: "Dashbard" },
      { title: "Security" },
    ]);
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
};
</script>

<style scoped>
.dashboard-mode {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 999;
  background: #333334;
}

.dashboard-mode > .row {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 10px;
  padding-bottom: 0;
}

.dashboard-mode > .quickfoot {
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 999;
  padding: 10px;
  background: #333334;
}
</style>