<template>
  <div v-if="enabled === true" :id="myId" class="full-content" :style="`height: ${ oheight }px; overflow:hidden;`">
    <slot :height="height" :fcId="myId" :makeElemId="makeElemId"></slot>
  </div>
  <div v-else-if="dynamic === true" :id="myId" :style="`height: ${ oheight }px; overflow:hidden;`">
    <slot :height="height" :fcId="myId" :makeElemId="makeElemId"></slot>
  </div>
  <div v-else>
    <slot></slot>
  </div>
</template>

<script>
import { v4 as UUID } from "uuid";
export default {
  name: "FullContent",
  data() {
    return {
      myId: "full-content-a",
      render: false,
      defaultExcessPadding: 0,
      height: 1024,
      oheight: 1024
    };
  },
  beforeDestroy() {
    this.$eventBus.off(`window-resize`);
    //this.$eventBus.off(`cache-crm:services`);
  },
  methods: {
    rerender() {
      //console.log("re-render", this.bodyHeight(), this.thisTop(), this.footerHeight(), this.excessHeight());
      const self = this;
      self.$data.oheight =
        this.bodyHeight() -
        this.thisTop() -
        this.footerHeight() -
        this.excessHeight();
      self.$data.height = this.oheight - this.getExtraElemHeights();
      //self.$data.render = false;
      //self.$nextTick(() => (self.$data.render = true));
    },
    thisTop() {
      let thisElem = window.document.getElementById(this.myId);
      if (this.$tools.isNullOrUndefined(thisElem)) return 0;
      let thisElemBounds = thisElem.getBoundingClientRect();
      return thisElemBounds.top;
    },
    bodyHeight() {
      return (
        window.document.getElementsByTagName("body")[0].clientHeight || 1024
      );
    },
    excessHeight() {
      if (this.decludeExcess === true) return 0;
      return this.defaultExcessPadding;
    },
    footerHeight() {
      if (this.decludeFooter === true) return 0;
      return this.getElemHeight(this.footerId || "kt_footer");
    },
    getElemHeight(elemId) {
      let thisElem = window.document.getElementById(elemId);
      if (this.$tools.isNullOrUndefined(thisElem)) return 0;
      return thisElem.clientHeight;
    },
    getExtraElemHeights() {
      let total = 0;
      for (let elem of this.additionalElementsToWatch || []) {
        total += this.getElemHeight(this.makeElemId(elem));
      }
      return total;
    },
    makeElemId(id) {
      return `${ this.myId }-${ id }`;
    }
  },
  mounted() {
    if (this.enabled !== true && this.dynamic !== true) return;
    this.$data.myId = `full-content-${ UUID() }`;
    const self = this;
    self.$eventBus.on(`window-resize`, () => {
      self.rerender();
      //setTimeout(() => self.rerender(), 1000);
    });
    setTimeout(() => self.rerender(), 100);
    setTimeout(() => self.rerender(), 1000);
    //
  },
  props: {
    enabled: Boolean,
    dynamic: Boolean,
    decludeExcess: Boolean,
    decludeFooter: Boolean,

    headerId: String,
    subHeaderId: String,
    footerId: String,

    additionalElementsToWatch: Array
  }
};
</script>
